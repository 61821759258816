import axios from "axios";
import qs from "qs";
import {
    ElMessageBox,
    ElMessage
} from 'element-plus';




// axios.defaults.baseURL = ''  //正式
// axios.defaults.baseURL = '/' //测试

if(process.env.NODE_ENV=='production'){
    //axios.defaults.baseURL = 'http://172.16.6.36:4002/CM-API'
    axios.defaults.baseURL = 'https://admin.srd.org.cn/CM-API/';
}else{
    axios.defaults.baseURL = '/'
}

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";


//先从浏览器的localStorage存储中提取token值
//const tokenStr = localStorage.getItem('token')

const cookiearray = document.cookie.split(';');
const this_cookie_arr = [];            

for(var i=0; i<cookiearray.length; i++) {
    var key = cookiearray[i].split('=')[0].replace(/^\s*|\s*$/g,"")
    this_cookie_arr[key] = cookiearray[i].split('=')[1]
}
const tokenStr = this_cookie_arr['token'];
// if (tokenStr) {
//     axios.defaults.headers.post["token"] = tokenStr;
// }

//设置超时
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
    config => {
  
        if(config.type=='json'){
            config.headers["Content-Type"] = "application/json";
        }
        if(tokenStr){
            config.headers["token"] = tokenStr;
        }
        config.headers["languageType"] = 0;

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        ElMessageBox(JSON.stringify(error), '请求异常', {
            confirmButtonText: '确定',
            callback: action => {}
        });
    }
);
export default {
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: qs.stringify(data),
            })
            .then(res => {
                if(res.data.code == 500){
                    ElMessage.error(res.data.msg)
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
        })
    },
    post2(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                type:"json",
                method: 'post',
                url,
                data: JSON.stringify(data),
            })
            .then(res => {
                if(res.data.code == 500){
                    ElMessage.error(res.data.msg)
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
        })
    },
    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data,
            })
            .then(res => {
                // console.log(res.data);
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
};
