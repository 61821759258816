import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },{
    path: '/map',
    name: 'map',
    component: () => import('@/views/Map.vue')
  },{
    path: '/seach',
    name: 'seach',
    component: () => import('@/views/Seach.vue')
  },{
    path: '/Aboutus/jianjie',
    name: 'jianjie',
    component: () => import('@/views/Aboutus/JianJie.vue')
  },{
    path: '/Aboutus/detial',
    name: 'detial',
    component: () => import('@/views/Aboutus/Detial.vue')
  },{
    path: '/Aboutus/fzlc',
    name: 'fzlc',
    component: () => import('@/views/Aboutus/Fzlc.vue')
  },{
    path: '/Aboutus/link',
    name: 'link',
    component: () => import('@/views/Aboutus/Link.vue')
  },{
    path: '/Aboutus/honor',
    name: 'honor',
    component: () => import('@/views/Aboutus/Honor.vue')
  },{
    path: '/News/list',
    name: 'Newslist',
    component: () => import('@/views/News/List.vue')
  },{
    path: '/News/detial',
    name: 'Newsdetial',
    component: () => import('@/views/News/Detial.vue')
  },{
    path: '/Serve/list',
    name: 'Servelist',
    component: () => import('@/views/Serve/List.vue')
  },{
    path: '/Serve/detial',
    name: 'Servedetial',
    component: () => import('@/views/Serve/Detial.vue')
  },{
    path: '/Serve/all',
    name: 'Serveall',
    component: () => import('@/views/Serve/All.vue')
  },{
    path: '/Hangye/list',
    name: 'Hangyelist',
    component: () => import('@/views/Hangye/List.vue')
  },{
    path: '/Hangye/detial',
    name: 'Hangyedetial',
    component: () => import('@/views/Hangye/Detial.vue')
  },{
    path: '/Hangye/all',
    name: 'Hangyeall',
    component: () => import('@/views/Hangye/All.vue')
  },{
    path: '/Advisory',
    name: 'advisory',
    component: () => import('@/views/Advisory.vue')
  },{
    path: '/Addorder',
    name: 'addorder',
    component: () => import('@/views/Addorder.vue')
  },{
    path: '/Login/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue')
  },{
    path: '/Login/register',
    name: 'register',
    component: () => import('@/views/Login/Register.vue')
  },{
    path: '/Login/forget',
    name: 'forget',
    component: () => import('@/views/Login/Forget.vue')
  },{
    path: '/Main/userinfo',
    name: 'userinfo',
    component: () => import('@/views/Main/Userinfo.vue')
  },{
    path: '/Main/order',
    name: 'mainorder',
    component: () => import('@/views/Main/Order.vue')
  },{
    path: '/Main/lsorder',
    name: 'mainlsorder',
    component: () => import('@/views/Main/Lsorder.vue')
  },{
    path: '/Main/ordershow',
    name: 'mainordershow',
    component: () => import('@/views/Main/Ordershow.vue')
  },{
    path: '/Main/orderping',
    name: 'mainorderping',
    component: () => import('@/views/Main/Orderping.vue')
  },{
    path: '/Main/Kehu',
    name: 'mainkehu',
    component: () => import('@/views/Main/Kehu.vue')
  },{
    path: '/Main/Kehuadd',
    name: 'mainkehuadd',
    component: () => import('@/views/Main/Kehuadd.vue')
  },{
    path: '/Main/Kehuedit',
    name: 'mainkehuedit',
    component: () => import('@/views/Main/Kehuedit.vue')
  },{
    path: '/Main/Fankui',
    name: 'mainfankui',
    component: () => import('@/views/Main/Fankui.vue')
  }

  
  
]


//保留 #
const router = createRouter({
    history: createWebHashHistory(),
    routes
});

// 去掉 #
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

export default router
