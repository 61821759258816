import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/style.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import animate from "animate.css";



const app = createApp(App);

app.use(animate);

import axios from './api/axios';
app.config.globalProperties.$http = axios;

app.config.globalProperties.$imgurl = 'https://admin.srd.org.cn/CM-API/';
//app.config.globalProperties.$imgurl = 'https://172.16.6.36:4002/CM-API/';

app.use(ElementPlus).use(store).use(router).mount('#app')
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
app.component(key, component)
}


// import VueCookies from "vue-cookies";
// Vue.use(VueCookies);
import cookie from 'vue-cookies'

app.config.globalProperties.$cookie = cookie;


app.use(VueAwesomeSwiper);


